/*jshint esversion: 11 */

import "./dialog.css";

/**
 * Simple Dialog function
 *
 * @param title
 * @param message
 * @param type
 * @param icon
 * @param timeout
 * @constructor
 */
function Dialog ( {
                      title,
                      message,
                      type = "info",
                      icon = null,
                      timeout = 3000
                  }, callback ) {
    const dlg = document.createElement( "div" );

    dlg.classList.add( "weModal" );

    let modal = document.getElementById( "weModal" );

    let dialog = `
		
        <div id="weModal" class="wemodal">
          <div class="wemodal-content">
             <article class="message">
                <div class="message-header  ${ type }">
                  <p><h2>${ title }</h2></p>
                </div>
                <div class="message-body">
                       <p>${ message }</p>
                </div>
              </article>
          </div>
        </div>`;

    dlg.innerHTML = dialog;

    document.body.prepend( dlg );

    if ( timeout != null )
        setTimeout( function () {
            const modal = document.querySelector( ".weModal" );
            modal.remove();

            if ( callback !== undefined && typeof callback === "function" ) {
                callback();
            }

        }, timeout );
}

export default Dialog;
