const glossary = {

  "404": {
    "cz": "Platnost odkazu vypršela",
    "de": "der Link ist abgelaufen",
    "en": "Link has expired",
    "es": "el link ha expirado",
    "fr": "Le lien a expiré",
    "gr": "ο σύνδεσμος έχει λήξει",
    "it": "Gentile cliente, il link è scaduto",
    "pl": "Link wygasł",
    "pt": "o link expirou",
    "ru": "срок действия ссылки истек"
  },

  "500": {
    "cz": "Chyba připojení k serveru",
    "de": "Serververbindungsfehler",
    "en": "Server connection error",
    "es": "Error de conexión del servidor",
    "fr": "Erreur de connexion au serveur",
    "gr": "Σφάλμα σύνδεσης διακομιστή",
    "it": "Errore di connessione al server",
    "pl": "Błąd połączenia z serwerem",
    "pt": "Erro de conexão do servidor",
    "ru": "Ошибка подключения к серверу"
  },
  "invalid-url": {
    "cz": "Nesprávná adresa webu",
    "de": "Falsche Website-Adresse",
    "en": "Incorrect website address",
    "es": "Dirección de sitio web incorrecta",
    "fr": "Adresse de site Web incorrecte",
    "gr": "Λανθασμένη διεύθυνση ιστότοπου",
    "it": "Indirizzo del sito web errato",
    "pl": "Błędny adres strony",
    "pt": "Endereço do site incorreto",
    "ru": "Неверный адрес веб-сайта"
  },
    "image_corrupted": {
        "cz": {     title: "Poškozená fotka",         message: "Jedna nebo více fotografií je poškozeno. Vyfoťte to prosím znovu."},
        "de": {     title: "Beschädigtes Foto",       message: "Ein oder mehrere Fotos sind beschädigt. Bitte machen Sie das Bild noch einmal."},
        "en": {     title: "Corrupted photo",         message: "One or more photos are corrupted. Please take the picture again."},
        "es": {     title: "foto corrupta",           message: "Una o más fotos están dañadas. Por favor, tome la foto de nuevo."},
        "fr": {     title: "Photo corrompue",         message: "Une ou plusieurs photos sont corrompues. Veuillez reprendre la photo."},
        "gr": {     title: "Κατεστραμμένη φωτογραφία",message: "Μία ή περισσότερες φωτογραφίες είναι κατεστραμμένες. Τραβήξτε ξανά τη φωτογραφία."},
        "it": {     title: "Foto danneggiata",        message: "Una o più foto sono danneggiate. Per favore, scatta di nuovo la foto."},
        "pl": {     title: "Uszkodzone zdjęcie",      message: "Jedno lub kilka zdjęć jest uszkodzonych. Proszę wykonać zdjęcie ponownie."},
        "pt": {     title: "foto corrompida",         message: "Uma ou mais fotos estão corrompidas. Por favor, tire a foto novamente."},
        "ru": {     title: "Поврежденное фото",       message: "Одна или несколько фотографий повреждены. Пожалуйста, сделайте снимок еще раз."}
  },
  "default": {
    "cz": "Neznámá chyba",
    "de": "Unbekannter Fehler",
    "en": "Unknown error",
    "es": "Error desconocido",
    "fr": "Erreur inconnue",
    "gr": "Αγνωστο σφάλμα",
    "it": "Errore sconosciuto",
    "pl": "Wystąpił nieznany błąd",
    "pt": "Erro desconhecido",
    "ru": "Неизвестная ошибка"
  }
}

export default glossary;
